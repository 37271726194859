export const white = '#fff';
export const dark = 'rgba(13, 13, 13,1)';
export const skeletonBack = 'rgba(13, 13, 13,0.1)';
export const skeletonDone = 'rgba(13, 13, 13,0.3)';
export const empty = '';
export const reset = 'RESET';
export const singleDate = 'singleDate';
export const multiDate = 'multiDate';
export const defDateFormat = 'yyyy-MM-dd';
export const none = 'none';

export const place_api = (google_api_key) => `https://maps.googleapis.com/maps/api/js?key=${google_api_key}&libraries=places`;
export const place_by_id = ({
								query,
								place_id
							}) => `https://www.google.com/maps/search/?api=1&query=${query}&query_place_id=${place_id}`;
export const place_info = ({
							   place_id,
							   google_api_key
						   }) => `https://maps.googleapis.com/maps/api/place/details/json?place_id=${place_id}&key=${google_api_key}`;
export const place_image = ({photo_reference, google_api_key}) => `https://maps.googleapis.com/maps/api/place/photo
?photo_reference=${photo_reference}&maxwidth=400&key=${google_api_key}`;

export const google_api_key = 'AIzaSyCJMncIytIgUG1fef-y0Frh2WJcFzpMBuA';
export const mapbox_key = 'pk.eyJ1IjoibWRqdWxmaWthciIsImEiOiJjbGptY2t3NWsweGg2M2xwazR3OTFvNW1hIn0.Q4GxGpusgC0DPm4nEavA8g';//'pk.eyJ1IjoiZWFzeXRlY2giLCJhIjoiY2p4NGo2MG55MDlkNDQzcnY3ZzkwNGVycyJ9.BHfK3jS2EfbVOevKk5_RIw';
export const proxyurl = "https://cors-anywhere.herokuapp.com/";
export const onesignal_key = '7425200f-031a-4fd7-9576-288e499d8ff1';//'10838f65-11ef-4b42-8ec7-9b41038766e4';
export const google_fcm_key = 'BMsJBNVCzx8gB_MOhubiAnimbGJ0CEvpVK640BYxyeu190Z0vd6qIKYfSKZY985sVoAOtF3CAqZ4LPW4tMVbj2E';

export const cookieClear = (callback) => {
	[
		ck_theme,
		ck_lang,
		ck_u_login_id,
		ck_u_username,
		ck_u_password,
		ck_u_client,
		ck_u_role_id,
		ck_u_remember_auth,
		ck_t_table,
		ck_t_search,
		ck_u_salesperson,
		ck_u_selforder,
		ck_u_device_no,
		ck_session_id
	].forEach(item => {
		callback(item);
	});
}

export const ck_theme = '@cookie:theme';
export const ck_lang = '@cookie:language';

export const ck_session_id = '@cookie:sessionId';

export const ck_t_table = '@cookie:tableInfoSetting';
export const ck_t_search = '@cookie:searchCritSetting';

export const ck_doc_columns = '@cookie:docTableColumns';

export const ck_checkin_columns = '@cookie:checkinTableColumns';
export const ck_st_columns = '@cookie:stColumns';
export const ck_st_dtl_columns = '@cookie:stDetailsColumns';

/**Official Doc keys */
export const ck_table_docType = '@cookie:docType';

export const ck_stocktable_list = '@cookie:stockTableType-list';
export const ck_stocktable_type = '@cookie:stockTableType';
export const ck_table_docDate = '@cookie:documentDate';
export const ck_table_lastPrintDate = '@cookie:lastPrintDate';
export const ck_table_custCode = '@cookie:custCode';
export const ck_table_delDate = '@cookie:deliveryDate';
export const ck_table_updatedDate = '@cookie:lastUpdatedDate';
export const ck_table_updatedBy = '@cookie:lastUpdatedBy';
export const ck_table_salesman = '@cookie:salesman';

export const ck_search_docDate = '@cookie:documentDate_s';
export const ck_search_lastPrintDate = '@cookie:lastPrintDate_s';
export const ck_search_customer = '@cookie:customer_s';
export const ck_search_delDate = '@cookie:deliveryDate_s';
export const ck_search_salesman = '@cookie:salesman_s';
export const ck_search_docId = '@cookie:docId_s';

export const ck_u_login_id = '@cookie:loginId';
export const ck_u_username = '@cookie:username';
export const ck_u_password = '@cookie:password';
export const ck_u_client = '@cookie:clientId';
export const ck_u_role_id = '@cookie:roleId';
export const ck_u_salesperson = '@cookie:isSalespersonCustomer';
export const ck_u_selforder = '@cookie:isSelfOrderCustomer';
export const ck_u_remember_auth = '@cookie:rememberMe';
export const ck_u_device_no = '@cookie:deviceNo';

export const yes = '1';
export const no = '0';

export const api_url_dev = 'https://api.easym.com.my/v1/';
export const api_url = 'https://api.easym.com.my/v1/';
export const no_image_file = 'https://easym.com.my/brand_logo/assets/broken-image.png';
export const no_image = 'https://lorempixels.com/1600/900/nature/';
export const socket_url = /*'http://35.187.244.151:9221';*/'https://calm-beyond-68220.herokuapp.com/';
export const databridge = "https://easysales-sigma.online:9211";
export const map_pin = 'https://upload.wikimedia.org/wikipedia/commons/d/d1/Google_Maps_pin.svg';
export const o2o_redirect = 'https://easysales.asia/admin/rest_api/customer_order_redirect/o2o.php?cust_code=@code&client=@client';
export const pdf_image = 'https://easysales.asia/admin/rest_api/uploads/img_pdf.png';
//export const pdf_image = 'https://easysales.asia/staging/PDF-image.png';
export const avg_price_report_url = 'https://easysales.asia/@client/easysales/cms/averagePriceReport.php?dateFrom=@dateFrom&dateTo=@dateTo';
export const cash_rolling_report_url = 'https://easysales.asia/@client/easysales/cms/CR_Report.php?date_from=@dateFrom&date_to=@dateTo&salesperson=@salesperson';
export const commission_report_url = 'https://easysales.asia/@client/easysales/cms/commissionReport.php?date_from=@dateFrom&date_to=@dateTo&salesperson=@salesperson';
export const driver_routing_report_url = 'https://easysales.asia/@client/easysales/cms/viewScheduleList.php?date=@date&salesperson_id=@salesperson&product_code=@product_code';
export const delivery_report_url = 'https://easysales.asia/@client/easysales/cms/DeliveryReport.php?date_from=@dateFrom&date_to=@dateTo&client=@client';
export const checkin_report_url = (client, salesperson_id, date_range, type, username, cust_code, cust_category_id, category, branch, json) => `https://easym.com.my/report/easym/cms/visitReport.php?client=${client}&reportDate=${encodeURIComponent(date_range)}&salespersonId=${salesperson_id}&type=${type}&username=${username}&cust_code=${cust_code}&cust_category_id=${cust_category_id}&image_category=${category}&branch=${branch}&json=${encodeURIComponent(json)}`;
export const summary_doc_report_url = 'http://easysales.asia/esv2/webview/iManage/getDailySalesSummary_Doc_BackOffice.php?client=@client&date_to=@date_to&date_from=@date_from&doc_type=@doc_type&salesperson_id=@salesperson_id';
export const summary_doc_item_report_url = 'http://easysales.asia/esv2/webview/iManage/getDailySalesSummary_Item_BackOffice.php?client=@client&date_to=@date_to&date_from=@date_from&doc_type=@doc_type&salesperson_id=@salesperson_id';
export const order_summary_url = 'https://webapp.easyecosystem.com/dashboard/';
export const st_report_url = 'https://easysales.asia/@client/exportBarcodeExcel.php?location=@location';

export const agent_attend_report_url = `https://easym.com.my/report/easym/cms/agentAttendReport.php?client=@client&reportDate=@reportDate&salespersonId=@salespersonId&username=@username`;
export const stock_take_report_url = `https://easym.com.my/report/easym/cms/exportStockTakeReport.php?client=@client&reportDate=@reportDate&salespersonId=@salespersonId&username=@username&cust_code=@cust_code&product_code=@product_code&cust_category_id=@cust_category_id&cust_chain_id=@cust_chain_id&prod_category_id=@prod_category_id&prod_brand_id=@prod_brand_id&branch=@branch&json=@json`;
export const stock_near_expiry_report_url = `https://easym.com.my/report/easym/cms/exportStockNearExpiryReport.php?client=@client&reportDate=@reportDate&salespersonId=@salespersonId&cust_code=@cust_code&username=@username&expiry_date=@expiry_date&product_code=@product_code&branch=@branch`;
export const oos_report_url = `https://easym.com.my/report/easym/cms/oosReport.php?client=@client&reportDate=@reportDate&salespersonId=@salespersonId&cust_code=@cust_code&cust_chain_id=@cust_chain_id&area=@area&username=@username&product_code=@product_code&branch=@branch`;
export const outlet_visit_report_url = `https://easym.com.my/report/easym/cms/exportOutletVisitReport.php?client=@client&reportDate=@reportDate&salespersonId=@salespersonId&username=@username&state=@state&region=@region&cust_code=@cust_code&branch=@branch`;
export const scheduled_visit_report_url = `https://easym.com.my/report/easym/cms/scheduleVisitReport.php?client=@client&reportDate=@reportDate&salespersonId=@salespersonId&cust_code=@cust_code&username=@username&status=@status`;
export const outlet_visit_frequency_report_url = `https://easym.com.my/report/easym/cms/outletVisitFrequencyReport.php?client=@client&reportDate=@reportDate&salespersonId=@salespersonId&username=@username&state=@state&region=@region&cust_code=@cust_code`;

export const databridge_listen = {
	signup: "signup",
	connect: "connect",
	signin: "signin",
	consume: "consume"
}
export const databridge_emit = {
	signin: "signin",
	notify: "notify"
}

export const post = 'POST';
export const get = 'GET';
export const put = 'PUT';

export const xHeaderClient = 'X-Client-Comp';
export const xUserName = 'X-User-Name';

export const req_login = 'login';
export const req_token = 'login/applogin';
export const req_devicetoken = 'devicetoken';
export const req_monthlysales = 'stat/monthlysales';
export const req_semiannualsales = 'stat/semiannualsales';
export const req_topsalesman = 'stat/topsalesman';
export const req_topcustomer = 'stat/topcustomer';
export const req_topitems = 'stat/topitems';
export const req_stat_agentattend = 'merchandising/stat/agentattend';
export const req_stat_oos = 'merchandising/stat/oos';
export const req_stat_commissionmerchandiser = 'merchandising/stat/commissionmerchandiser';
export const req_stat_commissioncategory = 'merchandising/stat/commissioncategory';
export const req_stat_totalst = 'merchandising/stat/totalst';
export const req_stat_totalphoto = 'merchandising/stat/totalphoto';
export const req_stat_timespent = 'merchandising/stat/timespent';
export const req_stat_photogallery = 'merchandising/stat/photogallery';
/**
@deprecation <--Start-->
 */
export const req_stat_montlySales = 'stat?monthly';
export const req_stat_semiAnnual = 'stat?semi_annual';
export const req_stat_topSalesman = 'stat?top_salesman';
export const req_stat_topCustomer = 'stat?top_customer';
export const req_stat_topItem = 'stat?top_item';
/**
@deprecation <--End-->
 */
export const req_checkin_sched_amend = 'merchandising/checkin/schedassigned';
export const req_checkin_summary = 'merchandising/checkin/checkinsummary';
export const req_checkin = 'merchandising/checkin/checkinlist';
export const req_last_sched = 'merchandising/checkin/lastsched';
export const req_checkin_merchand_appmnt = 'merchandising/checkin/merchandiseappmnt';
export const req_checkin_merchandising = 'merchandising/checkin/merchandising';
export const req_checkin_unsched = 'merchandising/checkin/unsched';
export const req_checkin_changeappmnt = 'merchandising/checkin/changeappointment';
export const req_checkin_activity = 'merchandising/checkin/activity';
export const req_checkin_sched = 'merchandising/checkin/sched';
export const req_checkin_sched_details = 'merchandising/checkin/scheddetails';
export const req_region = 'merchandising/checkin/region';
export const req_totalcustnotinlistcount = 'merchandising/checkin/totalcustnotinlistcount';
export const req_gallery_checkedPhotos = 'merchandising/photogallery/checkedphotos';
export const req_custnotinlist = 'merchandising/checkin/totalcustnotinlist';
export const req_checkin_stocktake = 'merchandising/checkin/stocktake';
export const req_checkin_stocktransfer = 'merchandising/checkin/stocktransfer';
export const req_checkin_images = 'merchandising/checkin/images';
export const req_checkin_delete = 'merchandising/checkin/deletecheckin';
export const req_checkin_import = 'merchandising/checkin/import';
export const req_checkin_export = 'merchandising/checkin/exporttemplate';

export const req_cust = 'customer';
export const req_cust_attachment = 'customer/atch';
export const req_cust_info = 'customer/customer';
export const req_cust_deepsearch = 'customer/deepsearch';
export const req_cust_itemtype = 'customer/itemtype';
export const req_cust_delivery = 'customer/deliveryinfo';
export const req_cust_statement = 'customer/statement';
export const req_cust_sched = 'customer/sched';
export const req_cust_status = 'customer/customerstatus';
export const req_custby_salesman = 'customer?salesman=';
export const req_cust_b2b_client = 'customer/b2bclient';
export const req_cust_insert = 'customer/cust';
export const req_branch_per_cust = 'customer/branch';
export const req_cust_area = 'customer/area';
export const req_salesman = 'salesperson';
export const req_salesman_location = 'salesperson/location';
export const req_warehouse = 'salesperson/warehouse';
export const req_custsalesman = 'salesperson/agentbycust';
export const req_agentcust = 'salesperson/agentcust';
export const req_merchandiser = 'salesperson/merchandiser';
export const req_cust_category = 'customer/cust_category';
export const req_cust_chain = 'customer/cust_chain';
export const req_cust_type = 'customer/cust_type';
export const req_cust_branches = 'customer/customerBranches';
export const req_cust_type_status = 'customer/cust_type_status';
export const req_cust_category_status = 'customer/cust_category_status';
export const req_cust_chain_status = 'customer/cust_chain_status';
export const req_cust_area_status = 'customer/cust_area_status';
export const req_category_cust = 'customer/category_cust';
export const req_chain_cust = 'customer/chain_cust';
export const req_type_cust = 'customer/type_cust';
export const req_area_cust = 'customer/area_cust';
export const req_cust_list = 'customer/customer_list';
export const req_cust_approve = 'customer/approve_cust';
export const req_cust_tmplt = 'customer/stock_template';
export const req_cust_category_skustandard = 'customer/category_skustandard';

export const req_official_doc_count = 'officialdoc/ordercount';
export const req_official_doc = 'officialdoc?';
export const req_official_doc_log = 'officialdoc/log';
export const req_official_doc_status = 'officialdoc/edit';
export const req_official_pm_status = 'officialdoc/pmedit';
export const req_official_doc_block = 'officialdoc/blockitem';
export const req_official_doc_cancel = 'officialdoc/cancel';
export const req_official_pm_cancel = 'officialdoc/pmcancel';
export const req_official_doc_item_cancel = 'officialdoc/cancelitem';
export const req_official_doc_item_edit = 'officialdoc/edititem';
export const req_official_doc_edit = 'officialdoc/editorder';
export const req_official_change_status = 'officialdoc/orderstatus';
export const req_official_update_fault = 'officialdoc/orderfault';
export const req_official_update_pmfault = 'officialdoc/pmfault';
export const req_official_doc_dtl = 'officialdoc?doc_id=';
export const req_official_signed_doc = 'officialdoc/signeddoc?doc_id=';
export const req_official_uploads = 'officialdoc/uploads?doc_id=';
export const req_official_doc_lumsumtransfer = 'officialdoc/lumsumtransfer';
export const req_b2b_orderdtl = 'officialdoc/b2borderdetails?order_id=';
export const req_b2b_order = 'officialdoc/b2border?';

export const req_delvery_create = 'delivery/jntcreate';
export const req_user_accounts = 'useraccounts/users';
export const req_user_accounts_agent = 'useraccounts/agent';
export const req_user_accounts_cust = 'useraccounts/customer';
export const req_reconciliation = 'reconciliation';
export const req_reconciliation_dn = 'reconciliation/debitnote';
export const req_reconciliation_cn = 'reconciliation/creditnote';
export const req_reconciliation_rcp = 'reconciliation/receipt';

export const req_stock_itemcategory = 'stock/itemcategory';
export const req_stock_itempackage = 'stock/itempackage';
export const req_stock = 'stock?category_id=';
export const req_stock_no_cat = 'stock';
export const req_stock_def = 'stock';
export const req_stock_pkg = 'stock/pkg';
export const req_stock_category = 'stock/category';
export const req_stock_image = 'stock/image';
export const req_stock_atch = 'stock/atch';
export const req_stock_vipqty = 'stock/vipqty';
export const req_stock_info = 'stock/item';
export const req_stock_uom = 'stock/itemuom';
export const req_stock_b2b = 'stock/itemb2b';
export const req_stock_status = 'stock/productstatus';
export const req_stocksl_status = 'stock/productslstatus';
export const req_category_status = 'stock/categorystatus';
export const req_stock_itemtype = 'stock/itemtype';
export const req_stock_itemtypedtl = 'stock/itemtypedtl';
export const req_stock_update_qty = 'stock/stockavailqty';
export const req_stock_catimage_insert = 'stock/categoryimage';
export const req_stock_catimage_remove = 'stock/removecatimage';
export const req_stock_insert = 'stock/stock';
export const req_all_stock = 'stock?product_status=';
export const req_stock_minqty = 'stock/minqty';
export const req_stock_clearance = 'stock/clearance';
export const req_stock_promotion = 'stock/promo';
export const req_report_jnt = 'report/jntreport';
export const req_report_agentattend = 'report/agentattend';
export const req_report_outletvisit = 'report/outletvisit';
export const req_report_outletvisitfrequency = 'report/outletvisitfrequence';
export const req_report_stocktake = 'report/stocktake';
export const req_stocktake_report = 'report/exportstocktake';
export const req_report_oos = 'report/oos';
export const req_report_oosreport = 'report/oosreport';
export const req_report_stocknearexpiry = 'report/stocknearexpiry';
export const req_report_schedulevisit = 'report/schedulevisit';
export const req_report_mileage = 'report/mileage';
export const req_report_mileagedetails = 'report/mileageDetails';
export const req_report_exportMileage = 'report/exportMileage';
export const req_delete_file = 'report/deleteFile';
export const req_appcontent = 'appcontent';
export const req_appcontent_announcement = 'appcontent/announcement';
export const req_module_setting = 'appcontent/module';
export const req_mobile_module_setting = 'appcontent/mobilemodule';
export const req_voucher_report = 'voucherreport';
export const req_appcontent_adver = 'appcontent/adver';
export const req_eventitems = 'eventitems';
export const req_eventitems_search = 'eventitems/search';
export const req_eventitems_dtl = 'eventitems/eventdtl';
export const req_eventitems_subdtl = 'eventitems/eventsubdtl';
export const req_eventitems_subdtl_search = 'eventitems/searchsubdtlbydtl';
export const req_eventItems_subdtl_category = 'eventitems/subdtlcategory';
export const req_stock_transfer = 'stocktransfer';
export const req_stock_transfer_dtl = 'stocktransfer?doc_id=';
export const req_stock_transfer_doc_status = 'stocktransfer/edit';
export const req_stock_transfer_doc_cancel = 'stocktransfer/cancel';
export const req_stock_transfer_doc_dtl_cancel = 'stockadjustment/editdtl';
export const req_stock_transfer_status = 'stocktransfer/status';
export const req_setting = 'appcontent/setting';
export const req_backendsync = 'backendsync';
export const req_backendsync_lastupdatedtime = 'backendsync/lastupdatedtime';
export const req_stock_take = 'stocktake';
export const req_stock_take_dtl = 'stocktake?doc_id=';
export const req_stock_take_status = 'stocktake/status';
export const req_stock_take_st = 'stocktake/stocktake';
export const req_coord = 'coordination';
export const req_stocktemplate = 'stocktemplate';
export const req_stocktemplate_dtl = 'stocktemplate/dtl';
export const req_stocktemplate_bind = 'stocktemplate/bind';
export const req_stock_brand = 'stock/brand';
export const req_stock_category_item = 'stock/category_item';
export const req_stock_brand_item = 'stock/brand_item';
export const req_stock_brandimage_insert = 'stock/brandimage';
export const req_stock_brandimage_remove = 'stock/removebrandimage';
export const req_brand_status = 'stock/brandstatus';
export const req_stocktemplate_seq = 'stocktemplate/sequence';
export const req_stocktemplate_dtlseq = 'stocktemplate/dtlseq';
export const req_stock_batch = 'stock/batch';

export const req_st = 'stocktarget';
export const req_st_stgcode = 'stocktarget?stg_code=';
export const req_st_attr = 'stocktarget/attr';
export const req_st_deletetarget = 'stocktarget/deletetarget';
export const req_st_category = 'stocktarget/category';
export const req_st_detailssamount = 'stocktarget/detailsamount';
export const req_st_tagattr = 'stocktarget/tagattr';
export const req_st_stock = 'stocktarget/stock';
export const req_st_tag = 'stocktarget/tag';
export const req_st_tag_stgcode = 'stocktarget/tag?stg_code='
export const req_st_itemtarget = 'stocktarget/itemtarget?id=';
export const req_st_tagtarget = 'stocktarget/tagtarget?id=';
export const req_st_targetdtl = 'stocktarget/targetdtl';
export const req_st_deleteTargetDetails = 'stocktarget/deleteTargetDetails';
export const req_st_deletetag = 'stocktarget/deletetag';
export const req_st_generatenew = 'stocktarget/generatenew';
export const req_st_salespersons = 'stocktarget/salespersons';
export const req_st_tagdetails = 'stocktarget/tagdetails';
export const req_stock_commission = 'stock/commission';
export const req_acc_project = 'accproject';
export const req_cash_rolling = 'cashrolling';
export const req_cash_rolling_salesperson = 'cashrolling?salesperson_id=';
export const req_stock_productseq = 'stock/productsequence';

export const req_bundle = 'bundle/bundle';
export const req_bundle_details = 'bundle/bundledetails?bundle_code='

export const req_dob = 'dob';
export const req_dob_dtl = 'dob?doc_id=';
export const req_dob_status = 'dob/edit';
export const req_dob_cancel = 'dob/cancel';
export const req_dob_dtl_cancel = 'dob/editdtl';

export const req_stock_adjustment = 'stockadjustment';
export const req_stock_adjustment_dtl = 'stockadjustment?doc_id=';
export const req_stock_adjustment_doc_status = 'stockadjustment/edit';
export const req_stock_adjustment_doc_cancel = 'stockadjustment/cancel';
export const req_stock_adjustment_doc_dtl_cancel = 'stockadjustment/editdtl';

export const req_send_sms = 'notification/sms';

export const req_user_list = 'settings/user';
export const req_user_details = 'settings/userDetails';
export const req_resign_user = 'settings/resignUser';
export const req_update_user = 'settings/updateUser';
export const req_copy_user = 'settings/copyUser';

export const req_settings_branches = 'settings/userBranches';
export const req_regions = 'settings/regions';
export const req_role = 'settings/role';
export const req_update_user_outlet = 'settings/updateUserOutlet';

export const req_gallery = 'gallery';
export const req_gallery_type = 'gallery/type';
export const req_gallery_competitor = 'gallery/competitor';
export const req_gallery_competitor_type = 'gallery/competitortype';
export const req_gallery_competitor_downloadphotos = 'gallery/competitorcheckedphotos';
export const req_gallery_type_status = 'gallery/status';

export const req_report_incentive = 'report/incentive';
export const req_report_exportincentive = 'report/exportincentive';

export const req_compt = 'competitor';
export const req_compt_details = 'competitor/details';
export const req_compt_product = 'competitor/product';
export const req_compt_productdetails = 'competitor/productdtl';
export const req_compt_productimage = 'competitor/productimg';
export const req_compt_category = 'competitor/category';
export const req_compt_categorydetails = 'competitor/categorydetails';
export const req_compt_categoryproduct = 'competitor/categoryproduct';
export const req_compt_categoryimage = 'competitor/categoryimage';
export const req_compt_unassigncategoryproduct = 'competitor/unassigncategoryproduct';

export const req_report_posm = 'report/posm';
export const req_report_posmreport = 'report/posmreport';
export const req_report_display = 'report/display';
export const req_report_displayreport = 'report/displayreport';
export const req_report_sku = 'report/sku';
export const req_report_skureport = 'report/skureport';

export const req_report_competitorpricecheck = 'report/competitorpricecheck';
export const req_report_competitorpricecheckreport = 'report/competitorpricecheckreport';
export const req_report_aaanalysis = 'report/agentattendanalysis';
export const req_report_aaanalysisreport = 'report/agentattendanalysisreport';
export const req_report_skustandard = 'report/skustandard';
export const req_report_skustandardreport = 'report/skustandardreport';
export const req_report_ptanalysis = 'report/phototakenanalysis';
export const req_report_ptanalysisreport = 'report/phototakenanalysisreport';
export const req_report_competitorpricecheckanalysis = 'report/competitorpricecheckanalysis';
export const req_report_competitorpricecheckanalysisreport = 'report/competitorpricecheckanalysisreport';
export const req_report_stocknearexpiryanalysis = 'report/stocknearexpiryanalysis';
export const req_report_stocknearexpiryanalysisreport = 'report/stocknearexpiryanalysisreport';
