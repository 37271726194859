import JString from "@easytech-international-sdn-bhd/jstring";
import { Button, IconButton, Tooltip, Typography, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../Services/AppContext";
import { grey } from "@material-ui/core/colors";
import PersistantData from "../../Services/PersistantData";
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { useCommonStyles } from "../../Styles";
import _ from "lodash";
import { ck_u_password, ck_u_username, defDateFormat, empty, req_cust, req_cust_area, req_mobile_module_setting, req_module_setting, req_report_competitorpricecheck, req_salesman, req_report_competitorpricecheckreport, req_delete_file } from "../../Helper/Constants";
import moment from "moment";
import VirtualListCB from "../../Components/VirtualListCB";
import API_Service, { axiosDefaultConfig, axiosURIencode } from "../../Services/API_Service";
import DateRangePickerModal from "../../Components/DateRangePickerModal";
import { format } from "date-fns";
import useCookieHook from "../../Services/useCookieHook";
import MUIDataTable from "mui-datatables";
import {Assessment as AssessmentIcon} from '@material-ui/icons';
import { useSnackbar } from "notistack";
import { getSetting } from "../../Helper/Helper";

const useStyles = makeStyles((theme) => ({
	viewInRow: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'left',
		marginTop: theme.spacing(1),
		rowGap: 10
	}, 
    card: {
		maxWidth: 200,
		minWidth: 200,
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightMedium,
	},
	textleft: {
		textAlign: "left"
	},
	dropzone: {
		width: '50%',
		borderRadius: 5
	},
	dropzoneText: {
		color: grey[600]
	},
	adverPaper: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		padding: theme.spacing(1.5)
	},
	adverView: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: theme.spacing(1)
	},
	textarea: {
		width: '100%'
	},
	dateBtn: {
		width: '100%',
		marginTop: theme.spacing(1)
	},
	statusBtn: {
		marginLeft: 30,
		width: 150
	},
	row: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center'
	},
	alignRight: {
		display: 'flex',
		marginBottom: 10,
		flex: 1,
		flexDirection: 'row'
	}
}));

export default function RCompetitorPriceCheck() {
    const tableHead = [
		{
			name: "checkin_date",
			label: "Date",
			options: {
				display: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					return moment(value).format('DD/MM/YYYY HH:mmA');
				}
			}
		},
        {
            name: "quarter",
            label: "Quarter",
            options: {
                display: true
            }
        },
		{
			name: "agent_name",
			label: "Agent",
			options: {
				display: true
			}
		},
        {
			name: "customer_area",
			label: "Area",
			options: {
				display: true
			}
		},
		{
			name: "customer_name",
			label: "Outlet",
			options: {
				display: true
			}
		},
		{
			name: "company",
			label: "Branch",
			options: {
				display: true,
				customBodyRender: (value) => value ? value.toUpperCase() : ''
			}
		},
        {
            name: "cmpt_code",
            label: "Competitor Code",
            options: {
                display: true
            }
        },
        {
            name: "cmpt_name",
            label: "Competitor Name",
            options: {
                display: true
            }
        },
		{
			name: "cust_category_name",
			label: "Outlet Category",
            options: {
                display: true
            }
		},
		{
			name: "cust_chain_name",
			label: "Outlet Chain",
            options: {
                display: true
            }
		},
        {
            name: "category_name",
            label: "Product Category",
            options: {
                display: true
            }
        },
        {
            name: "product_barcode",
            label: "Barcode",
            options: {
                display: true
            }
        },
        {
            name: "product_name",
            label: "Product Name",
            options: {
                display: true
            }
        },
        {
            name: "sales_price_per_unit",
            label: "Selling Price Per Unit (RM)",
            options: {
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
					return JString(value).inCurrency();
				}
            }
        },
		{
			name: "promotion_price_per_unit",
			label: "Promotion Price Per Unit (RM)",
			options: {
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
					return JString(value).inCurrency();
				}
            }
		},
        {
            name: "estimated_sales_unit",
            label: "Estimated Sales Qty",
            options: {
                display: true
            }
        },
        {
            name: "estimated_sales_price",
            label: "Estimated Sales (RM)",
            options: {
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
					return JString(value).inCurrency();
				}
            }
        }
	];
    const commonStyle = useCommonStyles();
    const classes = useStyles();
    const singleton = PersistantData.getInstance();
    const [gState, setGState] = useContext(AppContext);
    var {translation: language, client} = gState;
	if (typeof (language) === 'undefined') {
		language = singleton.getlang()
	}
    const [cookies, setCookie] = useCookieHook([ck_u_username, ck_u_password]);
    const [tableHeader, setTableHeader] = useState([]);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    // salesperson selection
    const [splist, setSPlist] = useState([]);
    const [spView, setSpView] = useState(false);
    const [reportSalesperson, setReportSalesperson] = useState(empty);
    const [reportSpSelected, setReportSpSelected] = useState(empty);

    // outlet selection
    const [outletList, setOutletList] = useState([]);
    const [outletView, setOutletView] = useState(false);
    const [reportOutlet, setReportOutlet] = useState(empty);
    const [reportOutletSelected, setReportOutletSelected] = useState(empty);

    // area selection
    const [areaView, setAreaView] = useState(false);
    const [areaList, setAreaList] = useState([]);
    const [selectedArea, setSelectedArea] = useState(empty);

    // datepicker
    const [openDateDoc, setDateDoc] = useState(false);
    const [dateRange, setDateRange] = useState({});

    const [competitorPriceCheckList, setCompetitorPriceCheckList] = useState([]);

	useEffect(() => {
		if (JString(gState.client).isValid()) {
            loadConfig(gState.client);
			getSalesmanList(gState.client);
			getOutletList(gState.client);
			// loadStocksList(gState.client);
			getAreaList(gState.client);
		}
		setTableHeader(tableHead);
	}, [gState.client, gState.translation]);

    const snack = (msg, type = 'success') => {
		enqueueSnackbar(msg, {
			variant: type,
			preventDuplicate: true,
			autoHideDuration: type === 'success' ? 2000 : 3000
		});
	}

    const loadConfig = (_client = client) => {
		API_Service.get(req_module_setting, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result == 1) {
				const _config = getSetting(extraData, 'customization', {});
			}
		});
	}

    const getSalesmanList = (_client = client) => {
		var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
		API_Service.get(`${req_salesman}${parameters}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {name, login_id, login, login_status} = extraData[idx];
					_data.push({name: name, secondary: login, value: login_id, status: parseInt(login_status)});
				}
				setSPlist(splist => (_data));
			}
		});
	}

	const getOutletList = (_client = client) => {
		var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
		API_Service.get(`${req_cust}${parameters}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			var {customer, inactive_customer} = extraData;
			if (parseInt(result) === 1) {
				var arr = [];
				for (let i = 0; i < customer.length; i++) {
					const {cust_id, cust_code, cust_company_name} = customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_id,
						status: 1
					});
				}
				for (let i = 0; i < inactive_customer.length; i++) {
					const {cust_id, cust_code, cust_company_name} = inactive_customer[i];
					arr.push({
						name: cust_company_name,
						secondary: cust_code,
						value: cust_id,
						status: 0
					});
				}
				setOutletList(outletList => (arr));
			}
		});
	}

    const getAreaList = (_client = client) => {
		API_Service.get(req_cust_area, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (parseInt(result) === 1) {
				var _data = [];
				for (let idx = 0; idx < extraData.length; idx++) {
					const {area_name, area_code, area_status} = extraData[idx];
					_data.push({name: area_name, secondary: area_code, value: area_code, status: area_status});
				}
				setAreaList(_data);
			}
		});
	}

    const getReportDate = () => {
		return !_.isEmpty(dateRange) ? moment(dateRange.startDate).format('DD MMMM YYYY') + " / " + moment(dateRange.endDate).format('DD MMMM YYYY') : moment(new Date()).format('DD MMMM YYYY') + " / " + moment(new Date()).format('DD MMMM YYYY');
	}

    const getCompetitorPriceCheck = (_client = client) => {
        var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(dateRange)) {
			st_date = format(dateRange.startDate, defDateFormat);
			st_date_to = format(dateRange.endDate, defDateFormat);
		}
		var parameters = {
			client: client,
			date_from: st_date,
			date_to: st_date_to,
			salespersonId: reportSpSelected,
			type: 'simple',
			username: cookies(ck_u_username),
			cust_code: reportOutletSelected,
			area: selectedArea
		};
		if (gState.company) {
			parameters.company = gState.company;
		}
		const params = axiosURIencode(parameters);
        API_Service.get(`${req_report_competitorpricecheck}?${params}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				setCompetitorPriceCheckList(competitorPriceCheckList => (extraData));
			} else {
				setCompetitorPriceCheckList(competitorPriceCheckList => ([]));
				snack(language.no_data, 'warning');
			}
		});
    }

    const generateReport = (type, _client = client) => {
		var st_date = '';
		var st_date_to = '';
		if (!_.isEmpty(dateRange)) {
			st_date = format(dateRange.startDate, defDateFormat);
			st_date_to = format(dateRange.endDate, defDateFormat);
		}
		const params = axiosURIencode({
			client: client,
			date_from: st_date,
			date_to: st_date_to,
			salespersonId: reportSpSelected,
			type: 'simple',
			username: cookies(ck_u_username),
			cust_code: reportOutletSelected,
			area: selectedArea
		});
        API_Service.get(`${req_report_competitorpricecheckreport}?${params}`, axiosDefaultConfig(_client)).then(({data}) => {
			const {result, extraData} = data;
			if (result) {
				window.open(extraData.url, '_blank');
				const delay = setTimeout(() => {
					API_Service.get(`${req_delete_file}?filename=${extraData.filename}`, axiosDefaultConfig(_client));
					clearTimeout(delay);
				}, 5000);
			} else {
				snack(language.no_data, 'warning');
			}
		}).catch(err => {
			snack('The data is too large, please simplify the requirements', 'warning');
		});
	}

    const ReportSPViewClose = (newValue) => {
		setSpView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setReportSpSelected(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setReportSalesperson(spname);
		}
	}

    const ReportOutletViewClose = (newValue) => {
		setOutletView(false);
		if (newValue) {
			var spvalue = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spvalue += newValue[i].value;
				} else {
					spvalue += newValue[i].value + ",";
				}
			}
			setReportOutletSelected(spvalue);

			var spname = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					spname += newValue[i].name;
				} else {
					spname += newValue[i].name + ",";
				}
			}
			setReportOutlet(spname);
		}
	}

    const AreaViewClose = (newValue) => {
		setAreaView(false);
		if (newValue) {
			var value = '';
			for (let i = 0; i < newValue.length; i++) {
				if (i == newValue.length - 1) {
					value += newValue[i].value;
				} else {
					value += newValue[i].value + ",";
				}
			}
			setSelectedArea(value);
		}
	}

    const openPrintPreview = () => {
		generateReport(1);
	}

	return (
		<div>
            <div className={classes.viewInRow}>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setSpView(true)}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{reportSalesperson ? _.truncate(reportSalesperson, {length: 20}) : language.salesperson}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setOutletView(true)}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{reportOutlet ? _.truncate(reportOutlet, {length: 20}) : language.customer}
					</Typography>
				</Button>
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setAreaView(true)}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{selectedArea ? _.truncate(selectedArea, {length: 20}) : "Area"}
					</Typography>
				</Button>
				{/* <Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => StockTakeProductViewClick()}
					endIcon={<KeyboardArrowDownRoundedIcon/>}>
					<Typography className={commonStyle.searchBtnFont}>
						{stockTakeProduct ? _.truncate(stockTakeProduct, {length: 20}) : language.items}
					</Typography>
				</Button> */}
				<Button
					style={{width: 300, marginRight: 10}}
					variant="outlined"
					onClick={() => setDateDoc(true)}>
					<Typography className={commonStyle.searchBtnFont}>
						{getReportDate()}
					</Typography>
				</Button>
				<Button
					size="small"
					color="primary"
					variant={'contained'}
					onClick={() => getCompetitorPriceCheck()}>
					<Typography className={commonStyle.searchBtnFont}>
						{language.view}
					</Typography>
				</Button>
			</div>
            <div style={{height: 20}}/>
            {
				competitorPriceCheckList.length > 0 && (
                    <MUIDataTable
						title={language.rCompetitorPriceCheck}
						data={competitorPriceCheckList}
						columns={tableHeader}
						options={{
							search: false,
							print: false,
							filter: false,
							download: false,
							selectableRows: 'none',
							rowsPerPage: 25,
							rowsPerPageOptions: [25, 40, 65, 100],
							responsive: 'scrollMaxHeight',
							onColumnViewChange: (changedColumn, action) => {
								if(tableHeader.some(item => changedColumn === item.name)){
									var index = tableHeader.findIndex(item => changedColumn === item.name);
								}
								switch(action){
									case 'add':
										tableHeader[index].options.display = true;
										break;
									case 'remove':
										tableHeader[index].options.display = false;
										break;
								}
								setTableHeader(tableHeader);
							},
							customToolbar: () =>
								<>
									<CheckinTableReportButton onGenerateReport={openPrintPreview}/>
								</>
						}}
					/>
                )
            }
            <VirtualListCB
				open={spView}
				setClose={data => ReportSPViewClose(data)}
				title={language.salesperson}
				data={splist}
				language={language}
				filteration={1}
			/>

			<VirtualListCB
				open={outletView}
				setClose={data => ReportOutletViewClose(data)}
				title={"Outlet"}
				data={outletList}
				language={language}
				filteration={1}
			/>

			<VirtualListCB
				open={areaView}
				setClose={data => AreaViewClose(data)}
				title={"Area"}
				data={areaList}
				language={language}
				filteration={0}
			/>

			<DateRangePickerModal
				setPositive={range => {
					setDateRange(range);
					setDateDoc(false)
				}}
				setNegative={() => {
					setDateDoc(false)
				}}
				setClose={() => {
					setDateDoc(false)
				}}
				open={openDateDoc}
				message={language.date_range}
				positive={language.ok}
			/>
        </div>
	)
}

function CheckinTableReportButton({onGenerateReport}) {
	return (
		<React.Fragment>
			<Tooltip title={'Generate Report'}>
				<IconButton
					edge={'end'}
					color={'primary'}
					onClick={() => onGenerateReport()} aria-label="addnew">
					<AssessmentIcon/>
				</IconButton>
			</Tooltip>
		</React.Fragment>
	)
}