import React, {Suspense} from "react";
import {Switch} from "react-router-dom";
import Route from "./Route";

import Dashboard from "../Pages/Dashboard";
import StockList from '../Pages/StockList';
import Customer from '../Pages/Customer';
import Checkin from '../Pages/Checkin';
import StockTake from '../Pages/StockTake';
import Report from '../Pages/Report';
// import ReportGenerator from "../Pages/ReportGenerator";
import StockTemplate from '../Pages/StockTemplate';
import managesched from '../Pages/ManageSched';
import RAgentAttend from '../Pages/Report/RAgentAttend';
import ROOS from '../Pages/Report/ROOS';
import ROutletVisit from '../Pages/Report/ROutletVisit';
import ROutletVisitFrequency from '../Pages/Report/ROutletVisitFrequency';
import RScheduleVisit from '../Pages/Report/RScheduleVisit';
import RStockTake from '../Pages/Report/RStockTake';
import RStockNearExpiry from '../Pages/Report/RStockNearExpiry';
import RMileage from "../Pages/Report/RMileage";
import UserSettings from "../Pages/Settings";
import Gallery from "../Pages/Gallery";
import RIncentive from "../Pages/Report/RIncentive";
import Competitor from "../Pages/Competitor";
import RPOSM from "../Pages/Report/RPOSM";
import AgentAttendanceInsight from "../Pages/Insight/AgentAttendance";
import OutletVisitedInsight from "../Pages/Insight/OutletVisited";
import RDisplay from "../Pages/Report/RDisplay";
import RSKU from "../Pages/Report/RSKU";
import RCompetitorPriceCheck from "../Pages/Report/RCompetitorPriceCheck";
import ROutletVisitAnalysis from "../Pages/Report/ROutletVisitAnalysis";
import CompetitorGallery from "../Pages/Gallery/competitor";
import RSKUStandard from "../Pages/Report/RSKUStandard";
import Tutorials from "../Pages/Tutorials";
import SystemSettings from "../Pages/Settings/system";
import RPhotoTakenAnalysis from "../Pages/Report/RPhotoTakenAnalysis";
import RCompetitorPriceCheckAnalysis from "../Pages/Report/RCompetitorPriceCheckAnalysis";
import RStockNearExpiryAnalysis from "../Pages/Report/RStockNearExpiryAnalysis";

export default function Routes() {

	return (
		<Suspense fallback={<div>Loading...</div>}>
			<Switch>
				<Route path={`/`} exact component={Dashboard}/>

				{/* <Route path={`/main/transactions`} component={OfficialDoc} /> */}

				{/* <Route path={`/main/useraccounts`} component={UserAccounts} /> */}

				{/* <Route path={`/main/reconciliation`} component={Reconciliation} /> */}

				<Route path={`/main/stocklist`} component={StockList}/>

				{/* <Route path={`/main/bundle`} component={Bundle} /> */}

				<Route path={`/main/customer`} component={Customer}/>

				{/* <Route path={`/main/appcontent`} component={AppContent} /> */}

				{/* <Route path={`/main/eventitems`} component={EventItems}/> */}
				{/* <Route path={`/main/bundle`} component={Bundle} /> */}

				<Route path={`/main/checkin`} component={Checkin}/>

				{/* <Route path={`/main/dob`} component={Dob}/> */}

				{/* <Route path={`/main/stocktransfer`} component={StockTransfer}/> */}

				<Route path={`/main/stocktemplate`} component={StockTemplate}/>

				{/* <Route path={`/main/target`} component={Target}/> */}

				<Route path={`/main/stocktake`} component={StockTake}/>

				{/* <Route path={`/main/cashrolling`} component={CashRolling}/> */}

				<Route path={`/main/report`} component={Report}/>

				<Route path={`/main/managesched`} component={managesched}/>

				<Route path={`/main/gallery`} component={Gallery}/>

				{/* <Route path={`/main/reportgenerator`} component={ReportGenerator}/> */}

				{/* <Route path={`/main/backendsync`} component={BackendSync}/> */}

				{/* <Route path={`/main/modulelinkage`} component={ModuleLinkage}/> */}

				{/* <Route path={`/main/b2border`} component={B2BOrder}/> */}


				<Route path={`/main/ragentattend`} component={RAgentAttend}/>
				<Route path={`/main/roos`} component={ROOS}/>
				<Route path={`/main/routletvisit`} component={ROutletVisit}/>
				{/* <Route path={`/main/routletvisitfrequency`} component={ROutletVisitFrequency}/> */}
				<Route path={`/main/rstocktake`} component={RStockTake}/>
				{/* <Route path={`/main/rschedulevisit`} component={RScheduleVisit}/> */}
				<Route path={`/main/rstocknearexpiry`} component={RStockNearExpiry}/>
				{/* <Route path={`/main/rmileage`} component={RMileage}/> */}
				<Route path={`/main/rincentive`} component={RIncentive}/>
				<Route path={`/main/rposm`} component={RPOSM}/>
				<Route path={`/main/routletvisitanalysis`} component={ROutletVisitAnalysis}/>
				{/* <Route path={`/main/rphototakenanalysis`} component={RPhotoTakenAnalysis}/> */}
				<Route path={`/main/rstocknearexpiryanalysis`} component={RStockNearExpiryAnalysis}/>
				<Route path={`/main/rdisplay`} component={RDisplay}/>
				<Route path={`/main/rsku`} component={RSKU}/>
				<Route path={'/main/rskustandard'} component={RSKUStandard}/>

				<Route path={`/main/usersettings`} component={UserSettings}/>
				<Route path={`/main/systemsettings`} component={SystemSettings}/>
				<Route path={`/main/tutorials`} component={Tutorials} />

				<Route path={`/main/aainsight`} component={AgentAttendanceInsight}/>
				<Route path={`/main/ovinsight`} component={OutletVisitedInsight}/>

				<Route path={`/main/competitor`} component={Competitor}/>
				<Route path={`/main/rcompetitorpricecheck`} component={RCompetitorPriceCheck}/>
				<Route path={`/main/competitorgallery`} component={CompetitorGallery}/>
				<Route path={`/main/rcompetitorpricecheckanalysis`} component={RCompetitorPriceCheckAnalysis}/>

				<Route component={Dashboard}/>

			</Switch>
		</Suspense>
	);
}
