import React, {useContext, useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import {Button, Chip, DialogContent, Grid, Paper} from "@material-ui/core";
import {de} from "../../Helper/Helper";
import Slide from "@material-ui/core/Slide";
import {fade, makeStyles, useTheme} from "@material-ui/core/styles";
import JString from "@easytech-international-sdn-bhd/jstring";
import API_Service, {axiosDefaultConfig, axiosPutConfig, axiosURIencode} from "../../Services/API_Service";
import AppContext from "../../Services/AppContext";
import {
    empty,
    req_cust_list,
    req_regions,
    req_resign_user,
    req_copy_user,
    req_role,
    req_salesman,
    req_update_user,
    req_update_user_outlet,
    req_user_details,
    reset,
    req_settings_branches
} from "../../Helper/Constants";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import EditIcon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import {useSnackbar} from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import {grey} from "@material-ui/core/colors";
import MuiPhoneNumber from "material-ui-phone-number";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import InfoIcon from "@material-ui/icons/Info";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import MUIDataTable from "mui-datatables";
import moment from "moment/moment";
import DoneIcon from "@material-ui/icons/Done";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import VirtualListCB from "../VirtualListCB";
import SendIcon from "@material-ui/icons/Send";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from '@material-ui/icons/FileCopy';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    appBar: {
        position: 'relative',
        paddingRight: '0!important'
    },
    root: {
        flexGrow: 1
    },
    grid: {
        marginTop: 10,
        paddingLeft: 20,
        paddingRight: 20
    },
    dlgContent: {margin: -20, marginTop: -10},
    paper: {
        padding: 10,
        marginBottom: 10
    },
    viewInRowSpace: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    input: {
        marginTop: 10,
        width: '48%',
        height: 50
    },
    remark: {
        marginTop: 10,
        width: '100%',
        height: 50
    },
    text: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightMedium,
    },
    bottomNav: {
        flex: 'auto'
    },
}));

const useSearchStyle = makeStyles((theme) => ({
    margin: {
        marginTop: theme.spacing(1),
        marginLeft: 0,
        paddingLeft: 0
    },
    root: {
        flexGrow: 1,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.black, 0.10),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.black, 0.15),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '35ch',
            '&:focus': {
                width: '60ch',
            },
        },
    },
}));

const labels = {
    // login_id: "Login ID",
    login: "Login",
    staff_code: "Staff Code",
    name: "Name",
    contact_no: "Contact No.",
    email: "Email",
    password: "Password",
    remark: "Remark",
    company: "Branch",
};

const itemDataCell = [
    {
        name: "cust_code",
        label: "Code",
        options: {
            display: true
        }
    },
    {
        name: "cust_company_name",
        label: "Name",
        options: {
            display: true
        }
    },
    {
        name: "company",
        label: "Branch",
        options: {
            display: true,
            customBodyRender: (value) => {
                if (value) {
                    const branch = JSON.parse(value);
                    return branch.map((b)=><Chip size="small" label={b.toUpperCase()}/>);
                }
                return '';
            }
        }
    },
    {
        name: "created_date",
        label: "Created Date",
        options: {
            display: true,
            filter: false,
            customBodyRender: (value) => {
                return (
                    moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
                )
            }
        }
    },
    {
        name: "updated_at",
        label: "Last Updated",
        options: {
            display: true,
            filter: false,
            customBodyRender: (value) => {
                return (
                    moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm')
                )
            }
        }
    },
    {
        name: "customer_status",
        label: "Status",
        options: {
            display: true,
            customBodyRender: (value) => {
                switch (value) {
                    case "1" :
                        return (<Chip size="small" icon={<DoneIcon/>} label="Active"/>);
                        break;
                    case "0" :
                        return (<Chip size="small" icon={<CloseIcon/>} label="Inactive"/>);
                        break;
                    default :
                        return (null);
                        break;
                }
            }
        }
    }
];

export default function UserSettingsDetails({open, setClose, language, selectedUserDetails}) {
    const classes = useStyles();
    const searchClass = useSearchStyle();
    const [axiosConfig, setAxiosConfig] = useState({});
    const [axiosPut, setAxiosPut] = useState({})
    const [gState] = useContext(AppContext);
    const [userDetails, setUserDetails] = useState({});
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [actionDialog, setActionDialog] = useState(false);
    const [fullSPList, setFullSPList] = useState([]);
    const [spList, setSpList] = useState([]);
    const radioGroupRef = React.useRef(null);
    const [actionTo, setActionTo] = useState(null);
    const [action, setAction] = useState(null);
    const [editorDialog, setEditorDialog] = useState(false);
    const [loginIDValue, setLoginIDFV] = useState(empty);
    const [loginValue, setLoginFV] = useState(empty);
    const [nameValue, setNameFV] = useState(empty);
    const [staffCodeValue, setStaffCodeFV] = useState(empty);
    const [passwordValue, setPasswordFV] = useState(empty);
    const [contactNoValue, setContactNoFV] = useState(empty);
    const [emailValue, setEmailFV] = useState(empty);
    const [remarkValue, setRemarkFV] = useState(empty);
    const [edited, setEdited] = useState(false);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const snack = (msg, type = 'success') => {
        enqueueSnackbar(msg, {
            variant: type,
            preventDuplicate: true,
            autoHideDuration: type === 'success' ? 2000 : 3000
        });
    }
    const [loading, setLoading] = useState(false);
    const [searchArr, setSearchArr] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [branchesList, setBranchesList] = useState([]);
    const [viewRegions, setViewRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState(empty);
    const [selectedRole, setSelectedRole] = useState(empty);
    const [selectedBranch, setSelectedBranch] = useState(empty);
    const [selectedLeader, setSelectedLeader] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [outletList, setOutletList] = useState([]);
    const [newOutletOpen, setNewOutletOpen] = useState(false);
    const [fullCustomerList, setFullCustomerList] = useState([]);
    const [selectedOTR, setSelectedOTR] = useState([]);

    useEffect(() => {
        if (JString(gState.client).isValid()) {
            var axiosGetConfig = axiosDefaultConfig(gState.client);
            var axiosPut = axiosPutConfig(gState.client);
            setAxiosConfig(axiosGetConfig);
            setAxiosPut(axiosPut);
            getRegions(axiosGetConfig);
            getRoles(axiosGetConfig);
            getBranches(axiosGetConfig);
        }
    }, [gState?.client]);

    useEffect(() => {
        if(selectedUserDetails.login_id){
            loadUserDetails(selectedUserDetails.login_id);
            API_Service.get(req_salesman, axiosConfig).then(({data}) => {
                if (data) {
                    var {result, extraData: api_Sp} = data;
                    result = JString(result).toNumber();
                    if (result === 1) {
                        var _data = [];
                        for (let idx = 0; idx < api_Sp.length; idx++) {
                            const {name, login_id} = api_Sp[idx];
                            if (login_id != selectedUserDetails.login_id) {
                                if (!gState.company) {
                                    _data.push({name: name, value: login_id});
                                } else {
                                    if ('company' in api_Sp[idx]) {
                                        const company = JSON.parse(gState.company);
                                        if (company.includes(api_Sp[idx].company)) {
                                            _data.push({name: name, value: login_id});
                                        }
                                    }
                                }
                            }
                        }
                        setSpList(spList => (_data));
                        setFullSPList(fullSPList => (api_Sp));
                    }
                }
            });
        }

    }, [open, selectedUserDetails]);

    useEffect(() => {
        // de("betty-effect", userDetails);
        if(userDetails.regions_id && regionList.length > 0){
            var selected = userDetails.regions_id.map((x)=>{
                var arr = regionList.filter((y)=>{
                    if(x == y.id){
                        return y;
                    }
                });
                return arr[0];
            });
            setViewRegions(selected);
        }

    }, [userDetails, regionList]);

    useEffect(() => {
        if(newOutletOpen){
            loadCustomerList();
        }
    }, [newOutletOpen]);

    const getRegions = (config = axiosConfig) => {
        API_Service.get(req_regions, config).then(({data}) => {
            const {result, extraData} = data;
            if (result) {
                extraData.push({id: reset, name: "-"});
                setRegionList(regionList => (extraData));
            }
        }).catch(de);
    }

    const getRoles = (config = axiosConfig) => {
        API_Service.get(req_role, config).then(({data}) => {
            const {result, extraData} = data;
            if (result) {
                extraData.push({role_id: reset, name: "-"});
                setRoleList(roleList => (extraData));
            }
        }).catch(de);
    }

    const getBranches = (config = axiosConfig) => {
        API_Service.get(req_settings_branches, config).then(({data}) => {
            const {result, extraData} = data;
            if (result) {
                // extraData.push({role_id: reset, name: "-"});
                setBranchesList(branchesList => (extraData));
            }
        }).catch(de);
    }

    const onClose = () => {
        setClose();
        setCurrentPage(0);
    }

    const loadUserDetails = (login_id ,config = axiosConfig) => {
        API_Service.get(`${req_user_details}?login_id=${login_id}`, config).then(({data}) => {
            const {result, extraData} = data;
            if(result){
                if(extraData.outlet){
                    setOutletList(extraData.outlet);
                }
                setUserDetails(extraData);
                setLoginIDFV(extraData.login_id);
                setLoginFV(extraData.login);
                setNameFV(extraData.name);
                setStaffCodeFV(extraData.staff_code);
                setPasswordFV(extraData.password);
                setContactNoFV(extraData.contact_no);
                setEmailFV(extraData.email);
                setRemarkFV(extraData.remark);
            }
        }).catch(de);
    }

    const loadCustomerList = (config = axiosConfig) => {
        var parameters = '';
		if (gState.company) {
			const param = axiosURIencode({
				company: gState.company,
			});
			parameters = `?${param}`;
		}
        API_Service.get(`${req_cust_list}${parameters}`, config).then(({data}) => {
            const {result, extraData} = data;
            if (parseInt(result) === 1) {
                var _arr = [];
                for (let i = 0; i < extraData.length; i++) {
                    const {cust_code, cust_company_name, customer_status, cust_category} = extraData[i];
                    if (customer_status === '1') {
                        _arr.push({
                            name: cust_company_name,
                            secondary: cust_code,
                            value: cust_code,
                            status: parseInt(customer_status)
                        });
                    }
                }

                if (_arr.length > 0) {
                    var filter = _arr.filter((obj) => {
                        return !outletList.some(obj2 => {
                            return obj.value === obj2.cust_code;
                        });
                    });
                    setFullCustomerList(fullCustomerList => (filter));
                } else {
                    // onSnack(language.no_data, 'warning');
                }
            }
        });
    }

    const resignUser = (data) => {
        setActionDialog(true);
        const _arr = [];
        for (let i = 0; i < data.length; i++) {
            var {dataIndex} = data[i];
            _arr.push(outletList[dataIndex]['cust_id']);
        }
        setSelectedOTR(_arr);
        setSearchArr(spList);
        setAction('resign');
    }

    const copyOutlet = (data) => {
        setActionDialog(true);
        const _arr = [];
        for (let i = 0; i < data.length; i++) {
            var {dataIndex} = data[i];
            _arr.push(outletList[dataIndex]['cust_id']);
        }
        setSelectedOTR(_arr);
        setSearchArr(spList);
        setAction('copy');
    }

    const actionDialogClose = () => {
        setActionDialog(false);
        setActionTo(null);
        setSearchArr(spList);
        setSelectedOTR([]);
        setAction(null);
    }

    const confirmDialog = (user_id, actions, config = axiosPut) => {
        var params = {
            login_id: selectedUserDetails.login_id,
            change_to: user_id,
            cust_id: selectedOTR
        };
        params = axiosURIencode(params);
        if(user_id){
            setLoading(true);
            switch(actions){
                case 'resign':
                    API_Service.put(`${req_resign_user}`, params, config).then(({data}) => {
                        var {result, message, extraData} = data;
                        if (result) {
                            snack("Success", 'success');
                            loadUserDetails(selectedUserDetails.login_id);
                            setActionDialog(false);
                            setActionTo(null);
                            setLoading(false);
                        } else {
                            snack("Failed", 'error');
                            setLoading(false);
                        }
                    });
                    break;
                case 'copy':
                    API_Service.put(`${req_copy_user}`, params, config).then(({data}) => {
                        var {result, message, extraData} = data;
                        if (result) {
                            snack("Success", 'success');
                            loadUserDetails(selectedUserDetails.login_id);
                            setActionDialog(false);
                            setActionTo(null);
                            setLoading(false);
                        } else {
                            snack("Failed", 'error');
                            setLoading(false);
                        }
                    });
                    break;
            }
        }
    }

    const handleChange = (event) => {
        if (event.target) {
            setActionTo(actionTo => (event.target.value));
        }
        event.persist();
    };

    const submitForm = (config = axiosPut) => {
        var params = {
            login_id: loginIDValue,
            login: loginValue,
            name: nameValue,
            staff_code: staffCodeValue,
            password: passwordValue,
            contact_no: contactNoValue,
            email: emailValue,
            remark: remarkValue,
            regions_id: selectedRegion,
            role_id: selectedRole,
            lead_id: selectedLeader
        };
        if (gState.company) {
            params.company = selectedBranch;
        }
        params = axiosURIencode(params);
        API_Service.put(req_update_user, params, config).then(({data}) => {
            var {result, message, extraData} = data;
            if (result) {
                snack("Success", 'success');
                seteditorclose();
                loadUserDetails(selectedUserDetails.login_id);
                setEdited(false);
            } else {
                snack("Failed", 'error');
            }
        });
    }

    const seteditorclose = () => {
        setEditorDialog(false);
        setLoginIDFV(empty);
        setLoginFV(empty);
        setNameFV(empty);
        setStaffCodeFV(empty);
        setPasswordFV(empty);
        setContactNoFV(empty);
        setEmailFV(empty);
        setRemarkFV(empty);
        setSelectedRegion(empty);
        setSelectedRole(empty);
        setSelectedBranch(empty);
        setSelectedLeader([]);
        setEdited(false);
    }

    const seteditoropen = () => {
        setEditorDialog(true);
        setLoginIDFV(userDetails.login_id);
        setLoginFV(userDetails.login);
        setNameFV(userDetails.name);
        setStaffCodeFV(userDetails.staff_code);
        setPasswordFV(userDetails.password);
        setContactNoFV(userDetails.contact_no);
        setEmailFV(userDetails.email);
        setRemarkFV(userDetails.remark);
        setSelectedRegion(userDetails.region_id);
        if(!userDetails.lead_id || userDetails.lead_id == 0){
            setSelectedLeader([]);
        }else{
            setSelectedLeader(userDetails.lead_id);
        }
        setSelectedRole(userDetails.role_id);
        setSelectedBranch(userDetails.company);
    }

    const onChangeSearchText = props => event => {
        var value = event.target.value;
        value = String(value).split('?').join('').split('[').join('').split(']').join('').split("(").join('').split(')').join('');
        var text = JString(value);
        var final = [];
        var search = (text.str).split(' ');
        if (text.isValid()) {
            for (let idx = 0; idx < spList.length; idx++) {
                var count = 0;
                const row = spList[idx];
                var searchString = row.value + row.name;
                for (let i = 0; i < search.length; i++) {
                    var isFound = JString(searchString).searchDeep(search[i]);
                    if (isFound) {
                        count++;
                    }
                }
                if (count === search.length) {
                    final.push(row);
                }
            }
            setSearchArr(final);
        } else {
            setSearchArr(spList);
        }
    }

    const handleChange2 = (event) => {
        if (event.target) {
            setSelectedRegion(selectedRegion => (event.target.value));
            setEdited(true);
        }
        event.persist();
    };

    const customerViewClose = (newValue) => {
        setNewOutletOpen(false);
        if (newValue) {
            if (newValue['name'] != 'reset') {
                var formdata = new FormData();
                formdata.append('login_id', userDetails.login_id);
                formdata.append('customer', JSON.stringify(newValue));
                if (userDetails?.company) {
                    formdata.append('company', userDetails?.company);    
                }
                formdata.append('status', 1);

                API_Service.post(req_update_user_outlet, formdata, axiosConfig).then(({data}) => {
                    var {result, extraData} = data;
                    if (result) {
                        snack("Update Success", 'success');
                        loadUserDetails(selectedUserDetails.login_id);
                    } else {
                        snack("Update Failed", 'error');
                    }
                });
            }
        }
    }

    const onRowDelete = (row) => {
        // de("betty-onRowDelete", row);
        const _arr = [];
        for (let i = 0; i < row.length; i++) {
            var {dataIndex} = row[i];
            _arr.push({
                name: outletList[dataIndex]['cust_company_name'],
                value: outletList[dataIndex]['cust_code']
            });
        }
        var formdata = new FormData();
        formdata.append('login_id', userDetails.login_id);
        formdata.append('customer', JSON.stringify(_arr));
        formdata.append('status', 0);
        API_Service.post(req_update_user_outlet, formdata, axiosConfig).then(({data}) => {
            var {result, extraData} = data;
            if (result) {
                snack("Update Success", 'success');
                loadUserDetails(selectedUserDetails.login_id);
            } else {
                snack("Update Failed", 'error');
            }
        });
    }

    const branchOnChange = (e) => {
        if (window.confirm("Switchting branch will clear all relation data. Are you sure you want to procees?")) {
            var _data = [];
            for (let idx = 0; idx < fullSPList.length; idx++) {
                const {name, login_id, company} = fullSPList[idx];
                if (login_id != selectedUserDetails.login_id && company === e.target.value) {
                    _data.push({name: name, value: login_id});
                }
            }
            setSpList(spList => (_data));
            setSelectedLeader([]);
            setSelectedBranch(e.target.value);
            setEdited(true);
        }
    }

    return (
        <div className={classes.root}>
            <Dialog fullScreen open={open} onClose={() => onClose()}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => onClose()} aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            { (currentPage == 0 && userDetails.role_id > 0) && (userDetails.role_name) }
                            { (currentPage == 0 && userDetails.role_id == 0) && (language.user_details) }
                            { currentPage == 1 && (language.user_outlet_details) }
                        </Typography>
                        {
                            currentPage == 0 && (
                                <Button
                                    onClick={seteditoropen}
                                    size="small">
                                    <EditIcon style={{color: 'black'}}/>
                                </Button>
                            )
                        }
                    </Toolbar>
                </AppBar>
                <DialogContent className={classes.dlgContent}>
                    {
                        currentPage == 0 && (
                            <Grid container spacing={3} className={classes.grid}>
                                <Grid item xs={4}>
                                    <Paper className={classes.paper}>
                                        {
                                            Object.keys(labels).map((key, idx) => {
                                                var listValue = '-';
                                                if (userDetails[key] != null) {
                                                    listValue = userDetails[key];
                                                }
                                                if (key === 'company' && listValue !== '-') {
                                                    listValue = listValue.toUpperCase();
                                                }
                                                return (
                                                    <div style={{marginTop: 10}} key={String(idx)}>
                                                        <Typography variant={'inherit'} component={'h4'}>
                                                            {labels[key]}
                                                        </Typography>
                                                        <Typography variant={'inherit'} component={'p'}>
                                                            {listValue}
                                                        </Typography>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Paper>
                                </Grid>
                                <Grid item xs={4}>
                                    <Paper className={classes.paper}>
                                        <div style={{marginTop: 10}}>
                                            <Typography variant={'inherit'} component={'h4'}>
                                                {language.user_access_regions}
                                            </Typography>
                                        </div>
                                        {/*<div style={{marginTop: 10}}>*/}
                                        {
                                            userDetails.region_id > 0 ?
                                                <Typography variant={'inherit'} component={'p'}>{userDetails.region_name}</Typography>
                                            :   <Typography variant={'inherit'} component={'p'}>{language.regions_no_data}</Typography>
                                        }
                                        {/*</div>*/}
                                    </Paper>
                                    <Paper className={classes.paper}>
                                        <div style={{marginTop: 10}}>
                                            <Typography variant={'inherit'} component={'h4'}>
                                                User Role
                                            </Typography>
                                        </div>
                                        {
                                            userDetails.role_id > 0 ?
                                                <Typography variant={'inherit'} component={'p'}>{userDetails.role_name}</Typography>
                                            :   <Typography variant={'inherit'} component={'p'}>No role is assigned to this user.</Typography>
                                        }
                                    </Paper>
                                    <Paper className={classes.paper}>
                                        <div style={{marginTop: 10}}>
                                            <Typography variant={'inherit'} component={'h4'}>
                                                User Group
                                            </Typography>
                                        </div>
                                        {
                                            (userDetails.group && userDetails.group.length > 0) ?
                                                (
                                                    userDetails.group.map((data) => {
                                                        return (<Typography variant={'inherit'} component={'p'}>{data.role} : {data.name}</Typography>);
                                                    })
                                                )
                                            :   <Typography variant={'inherit'} component={'p'}>No group found under this user.</Typography>
                                        }
                                    </Paper>
                                </Grid>
                            </Grid>
                        )
                    }
                    {
                        currentPage == 1 && (
                            <Grid container spacing={3} className={classes.grid}>
                                <Grid item xs={12}>
                                    <MUIDataTable
                                        title={language.user_outlet_list}
                                        data={outletList}
                                        columns={itemDataCell}
                                        options={{
                                            print: false,
                                            filter: false,
                                            rowsPerPage: 99,
                                            rowsPerPageOptions: [125, 140, 165, 200],
                                            responsive: 'scrollMaxHeight',
                                            download: false,
                                            viewColumns: false,
                                            selectableRows: outletList.length > 0 ? 'multiple' : 'none',
                                            customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
                                                const data = selectedRows?.data;
                                                return (
                                                    <div style={{marginRight: 25}}>
                                                        <ToolbarCustomSelect
                                                            title={'Copy outlet to'}
                                                            onClick={() => {
                                                                copyOutlet(data)
                                                            }}
                                                        >
                                                            <FileCopyIcon/>
                                                        </ToolbarCustomSelect>
                                                        <ToolbarCustomSelect
                                                            title={'Resign'}
                                                            onClick={() => {
                                                                resignUser(data)
                                                            }}
                                                        >
                                                            <SendIcon/>
                                                        </ToolbarCustomSelect>
                                                        <ToolbarCustomSelect
                                                            title={'Delete'}
                                                            onClick={() => {
                                                                onRowDelete(data)
                                                            }}
                                                        >
                                                            <DeleteIcon/>
                                                        </ToolbarCustomSelect>
                                                    </div>
                                                )
                                            },
                                            onRowsDelete: ({data: row}) => onRowDelete(row),
                                            setTableProps: () => ({size: 'small'}),
                                            customToolbar: () =>
                                                <TemplateTableCustomToolbar
                                                    addCustomer={() => {
                                                        setNewOutletOpen(true)
                                                    }}
                                                />
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )
                    }
                </DialogContent>
                <DialogActions>
                    <BottomNavigation
                        value={currentPage}
                        onChange={(event, newValue) => {
                            setCurrentPage(newValue);
                        }}
                        showLabels
                        className={classes.bottomNav}>
                        <BottomNavigationAction label="Info" icon={<InfoIcon/>}/>
                        <BottomNavigationAction label="Outlet" icon={<PersonOutlineIcon/>}/>
                    </BottomNavigation>
                </DialogActions>
            </Dialog>

            <Dialog
                fullScreen={fullScreen}
                maxWidth={'lg'}
                open={actionDialog}
                onClose={() => actionDialogClose()}
                aria-labelledby="responsive-dialog-title">
                <DialogTitle
                    id="responsive-dialog-title">
                    {
                        action == 'resign' && (
                            <>
                            {language.resigning}
                            <p className={classes.text}>{language.resign_user_confirm1}<br/>{language.resign_user_confirm2}</p>
                            </>
                        )
                    }
                    {
                        action == 'copy' && (
                            <>
                            {language.copy_user}
                            <p className={classes.text}>{language.copy_user_confirm1}<br/>{language.copy_user_confirm2}</p>
                            </>
                        )
                    }
                    <div className={classes.viewInRow}>
                        <div className={searchClass.search}>
                            <div className={searchClass.searchIcon}>
                                <SearchIcon/>
                            </div>
                            <InputBase
                                placeholder={language.search}
                                classes={{
                                    root: searchClass.inputRoot,
                                    input: searchClass.inputInput,
                                }}
                                inputProps={{'aria-label': 'search'}}
                                onChange={onChangeSearchText('search')}
                            />
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent style={{paddingTop: 0}}>
                    <div style={{flexDirection: 'column'}}>
                        <RadioGroup
                            ref={radioGroupRef}
                            value={actionTo}
                            onChange={handleChange}
                            style={{width: 600}}>
                            {searchArr.map((option, idx) => {
                                if (idx !== searchArr.length) {
                                    return (
                                        <FormControlLabel
                                            value={option.value}
                                            key={String(idx)}
                                            control={<Radio/>}
                                            label={option.name}/>
                                    )
                                } else {
                                    return (
                                        null
                                    )
                                }
                            })}
                        </RadioGroup>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => actionDialogClose()} color="inherit" disabled={loading}>
                        {language.cancel}
                    </Button>
                    <Button onClick={()=>confirmDialog(actionTo, action)} color="inherit" autoFocus disabled={actionTo == null || loading}>
                        {
                            loading ? <CircularProgress size={20}/> : language.ok
                        }
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullScreen={fullScreen}
                maxWidth={'sm'}
                open={editorDialog}
                onClose={seteditorclose}
                aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title" style={{paddingBottom: 5}}>
                    {language.edit}
                </DialogTitle>
                <DialogContent>
                    <div className={classes.viewInRowSpace}>
                        {/* <TextField
                            className={classes.input}
                            id="input-login-id"
                            label={"Login ID"}
                            value={loginIDValue}
                            disabled={true}
                            onChange={(e) => setLoginIDFV(e.target.value)}
                        /> */}
                        <TextField
                            id="input-staff-code"
                            className={classes.input}
                            label={"Staff Code"}
                            value={staffCodeValue}
                            disabled={true}
                            onChange={(e) => setStaffCodeFV(e.target.value)}
                        />
                        <TextField
                            id="input-login"
                            className={classes.input}
                            label={'Login'}
                            value={loginValue}
                            disabled={true}
                            onChange={(e) => setLoginFV(e.target.value)}
                        />
                        <TextField
                            id="input-name"
                            className={classes.input}
                            label={'Name'}
                            value={nameValue}
                            onChange={(e) => {
                                setNameFV(e.target.value);
                                setEdited(true);
                            }}
                        />
                        <TextField
                            id="input-password"
                            className={classes.input}
                            label={language.password}
                            value={passwordValue}
                            type="password"
                            onChange={(e) => {
                                setPasswordFV(e.target.value);
                                setEdited(true);
                            }}
                        />
                        <MuiPhoneNumber
                            className={classes.input}
                            name="phone"
                            label={language.phone_number}
                            InputLabelProps={{
                                style: {color: grey[500]},
                            }}
                            data-cy="user-phone"
                            defaultCountry={"my"}
                            onlyCountries={['my']}
                            value={contactNoValue}
                            onChange={prop => {
                                setContactNoFV(prop);
                                setEdited(true);
                            }}
                            countryCodeEditable={false}
                            fullWidth
                            margin={'dense'}
                            regions={['asia']}
                        />
                        <TextField
                            id="input-email"
                            className={classes.input}
                            label={"Email"}
                            autoComplete="off"
                            value={emailValue}
                            onChange={(e) => {
                                setEmailFV(e.target.value);
                                setEdited(true);
                            }}
                        />
                        <FormControl className={classes.input}>
                            <InputLabel id="demo-simple-select-label">{language.region}</InputLabel>
                            <Select
                                value={selectedRegion}
                                onChange={(e) => {setSelectedRegion(e.target.value);setEdited(true);}}
                            >
                                {regionList.map((region) => (
                                    <MenuItem
                                        value={region.id}
                                        key={region.id}
                                    >
                                        {region.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.input}>
                            <InputLabel id="demo-simple-select-label">Role</InputLabel>
                            <Select
                                value={selectedRole}
                                onChange={(e) => {
                                    // if(e.target.value == 1){
                                    //     setSelectedLeader([]);
                                    // }
                                    setSelectedRole(e.target.value);
                                    setEdited(true);
                                }}
                            >
                                {roleList.map((role) => (
                                    <MenuItem
                                        value={role.role_id}
                                        key={role.role_id}
                                    >
                                        {role.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {
                            selectedRole != 0 && (
                                <FormControl className={classes.input}>
                                    <InputLabel id="demo-simple-select-label">Manager</InputLabel>
                                    <Select
                                        value={selectedLeader}
                                        onChange={(e) => {setSelectedLeader(e.target.value);setEdited(true);}}
                                        multiple
                                    >
                                        {spList.map((saleperson) => (
                                            <MenuItem
                                                value={saleperson.value}
                                                key={saleperson.value}
                                            >
                                                {saleperson.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )
                        }
                        {
                            branchesList.length > 0 && (
                                <FormControl className={classes.input}>
                                    <InputLabel id="demo-simple-select-label">Branch</InputLabel>
                                    <Select
                                        disabled={gState.company}
                                        value={selectedBranch}
                                        onChange={(e) => {
                                            branchOnChange(e);
                                        }}
                                    >
                                        {branchesList.map((role) => (
                                            <MenuItem value={role.value} key={role.value}>{role.name.toUpperCase()}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )
                        }
                        <TextField
                            id="input-remark"
                            className={classes.remark}
                            label={language.remark}
                            value={remarkValue}
                            onChange={(e) => {
                                setRemarkFV(e.target.value)
                                setEdited(true)
                            }}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={edited ? false : true}
                        variant="outlined"
                        onClick={()=>submitForm()}
                        color={'primary'}>
                        {language.update}
                    </Button>
                </DialogActions>
            </Dialog>

            {
                newOutletOpen && (
                    <VirtualListCB
                        setClose={(d) => customerViewClose(d)}
                        open={newOutletOpen}
                        title={'New User Outlet'}
                        data={fullCustomerList}
                        language={language}
                        filteration={0}
                    />
                )
            }
        </div>
    )
}

function TemplateTableCustomToolbar({addCustomer}) {
    return (
        <React.Fragment>
            <Tooltip title={'Add Outlet'}>
                <IconButton
                    edge={'end'}
                    color={'primary'}
                    onClick={() => addCustomer()} aria-label="addnew">
                    <AddIcon/>
                </IconButton>
            </Tooltip>
        </React.Fragment>
    )
}

export function ToolbarCustomSelect({onClick, title, children}) {
    return (
        <React.Fragment>
            <Tooltip title={title}>
                <IconButton onClick={() => onClick()}>
                    {children}
                </IconButton>
            </Tooltip>
        </React.Fragment>
    )
}
